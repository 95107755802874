<template>
  <div>
    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
            data.index +
            1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark">
          <feather-icon icon="ImageIcon" /> </b-avatar>{{ "  " }}
        {{ data.item.ref }}
      </template>

      <template #cell(forms)="data">
        {{ (data && data.item.fiches && data.item.fiches.length) || 0 }}
      </template>

      <template #cell(canValidateFicheStatMensuel)="data">
        <b-badge
          v-if="data.item.canValidateFicheStatMensuel"
          variant="primary"
        >
          Autorisé
        </b-badge>
        <b-badge
          v-else
          variant="danger"
        >
          Non Autorisé
        </b-badge>
        <!-- <span >Autorise</span>
        <span v-else>Non Autoriseé</span> -->
      </template>

      <template #cell(actions)="data">
        <b-button
          variant="primary"
          :to="{
            name: 'etablissements-agents-details',
            params: { agentId: data.item._id },
          }"
        >
          <feather-icon icon="EyeIcon" />
          <span class="text-nowrap"> Détails</span>
        </b-button>
      </template>
    </b-table>
    <div>
      <PaginationComponent
        :table-data="tableData"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </div>
    <!-- <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <span
          class="my-auto"
        >Affichage de {{ paginationData.metaData.from }} à
          {{ paginationData.metaData.to }} sur {{ paginationData.total }}
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCardText,
  BBadge,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PaginationComponent from '@/components/PaginationComponent.vue'
import utilsService from '@/services/utils/utils.service'
// store module and vuex utilities
import etablissementsStroreModule from '@/store/etablissements'
import formulairesStroreModule from '@/store/formulaire'
import paramsStoreModule from '@/store/params'
import usersStoreModule from '@/store/users'
import {
  registerStoreModule,
  unregisterStoreModule,
} from '@/helpers/vuex-utils'

export default {
  setup() {
    const requiredStoreModules = [
      { path: 'etablissements', module: etablissementsStroreModule },
      { path: 'formulaires', module: formulairesStroreModule },
      { path: 'params', module: paramsStoreModule },
      { path: 'users', module: usersStoreModule },
    ]
    // Register module
    registerStoreModule(requiredStoreModules)

    return { requiredStoreModules }
  },

  name: 'ListAgentsComponent',
  components: {
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCardText,
    BBadge,
    ToastificationContent,
    PaginationComponent,
  },

  props: { etablissementId: null },
  data() {
    return {
      confirmeArchiveAgentModalRef: 'confirmeArchiveAgentModal',

      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        {
          key: 'id', stickyColumn: true, label: '#', sortable: true,
        },
        {
          key: 'firstname', stickyColumn: true, label: 'Nom', sortable: true,
        },
        {
          key: 'lastname', stickyColumn: true, label: 'Prénom', sortable: true,
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          stickyColumn: true,
        },
        // {
        //   key: 'etablissement',
        //   label: 'Etablissement',
        //   sortable: true,
        // },
        {
          key: 'forms',
          label: 'Nombre de fiches remplies',
          sortable: true,
          stickyColumn: true,
        },
        {
          key: 'canValidateFicheStatMensuel',
          label: 'Validation Fiches Mensuelles',
          sortable: true,
          stickyColumn: true,
        },
        'actions',
      ],
      isLoadingTableData: false,
    }
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules)
  },
  computed: {
    ...mapGetters('params', {
      typesRoles: 'getter_typesRoles',
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0
    },
    isAdminOrAgentMTCA() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'agent_mtca' || userRole.code === 'super_admin'
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1),
          )
        }
      },
    },
    tableData: {
      immediate: false,
      deep: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData)
        }
        if (val && val.length >= 3) {
          this.$emit('canAddNewAgent', false)
        } else {
          this.$emit('canAddNewAgent', true)
        }
      },
    },
    'paginationData.currentPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
    'paginationData.perPage': {
      handler(val, old) {
        this.getListUsers()
      },
    },
    $route: {
      immediate: true,
      async handler(val, old) {
        this.getListUsers()
      },
    },
  },
  async mounted() {},
  methods: {
    ...mapActions('users', { 
      action_fetchUsers: 'fetchUsers', 
      action_fetchAgentsEtab: 'fetchAgentsEtab' ,
    }),
    ...mapActions('params', { action_fetchTypesRoles: 'fetchTypesRoles' }),
    ...mapActions('etablissements', { getAgent: 'getAgent' }),
    ...mapActions('formulaires', { getFormulaire: 'getFormulaire' }),
    // --------------------------------------------------------------------
    async getListUsers() {
      this.isLoadingTableData = true
      if (this.isAdminOrAgentMTCA){
        const userRoleReq = await this.action_fetchTypesRoles().catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        if (!userRoleReq || !userRoleReq.data) {
          this.isCheckingAgentCount = false
          this.canAddNewAgent = false
          return
        }
        const { resources } = userRoleReq.data
        const userRole = (resources || []).find(
          e => e.code === 'agent_etablissement',
        )
        const userRoleId = userRole._id
        
        const params = {
          options: {
            role: userRoleId,
            isArchive: false,
            ...(this.etablissementId && { etablissement: this.etablissementId }),
          },
          page: this.paginationData.currentPage - 1,
          perPage: this.paginationData.perPage,
        }
        this.action_fetchUsers(params)
        .then(async response => {
          this.treatmentAfterResponse(response)
        })
        .catch(err => {
          this.isLoadingTableData = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
        const params = {
          options: {
            // role: userRoleId,
            // isArchive: false,
            ...(this.etablissementId && { etablissementId: this.etablissementId }),
          },
          page: this.paginationData.currentPage - 1,
          perPage: this.paginationData.perPage,
        }
        this.action_fetchAgentsEtab(params)
        .then(async response => {
          this.treatmentAfterResponse(response)
        })
        .catch(err => {
          this.isLoadingTableData = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
      // this.action_fetchAgentsEtab(params)
      //   .then(async response => {
      //     this.treatmentAfterResponse(response)
      //   })
      //   .catch(err => {
      //     this.isLoadingTableData = false
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: err.message || 'Une erreur est survenue',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //       },
      //     })
      //   })
    },
    async treatmentAfterResponse(response){
      this.tableData = response.data.resources || []
          // get forms by agents
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.tableData[i].fiches && this.tableData[i].fiches.length > 0) {
              const forms = []
              for (let j = 0; j < this.tableData[i].fiches.length; j++) {
                if (this.tableData[i].fiches[j].key && this.tableData[i].fiches[j].id) {
                  // load fiche
                  await this.getFormulaire({ formType: this.tableData[i].fiches[j].key, id: this.tableData[i].fiches[j].id })
                    .then(res => {
                      forms.push(res.data.resource)
                    })
                    .catch(err => {
                      console.log(err)
                    })
                }
              }
              this.tableData[i].forms = forms
            }
          }

          this.paginationData.total = response.data.total || 0
          this.isLoadingTableData = false

          if (this.paginationData.metaData.from == 0) {
            this.paginationData.metaData.from += 1
          } else {
            this.paginationData.metaData.from = this.paginationData.perPage * this.paginationData.currentPage
              - this.paginationData.perPage
          }

          this.paginationData.metaData.to = this.paginationData.perPage * this.paginationData.currentPage
            - this.paginationData.perPage
            + this.tableData.length
    },
    onPaginationChange(val) {
      this.paginationData = { ...this.paginationData, ...val }
    },
  },
}
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
