import formulairesService from "@/services/http/formulaires.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async fetchFormulaires({ rootState }, data) {
      const res = await formulairesService.fetchFormulaires(data);
      return res;
    },
    async searchFormulaires({ rootState }, data) {
      const res = await formulairesService.searchFormulaires(data);
      return res;
    },
    async exportFormulaires({ rootState }, data) {
      const res = await formulairesService.exportFormulaires(data);
      return res;
    },
    async getFormulaire({ rootState }, data) {
      const res = await formulairesService.getFormulaire(data);
      return res;
    },
    async updateFormulaire({ rootState }, data) {
      const res = await formulairesService.updateFormulaire(data);
      return res;
    },
    async updateFormulaireFormData({ rootState }, data) {
      const res = await formulairesService.updateFormulaireFormData(data);
      return res;
    },
    async addFormulaire({ rootState }, data) {
      const res = await formulairesService.addFormulaire(data);
      return res;
    },
    async getLastFormulaireCreatedByUser({ rootState }, data) {
      const res = await formulairesService.getLastFormulaireCreatedByUser(data);
      return res;
    },
    async getFormulairesGrandPublic({ rootState }, data) {
      const res = await formulairesService.getFormulairesGrandPublic(data);
      return res;
    },
  },
};
