import { render, staticRenderFns } from "./DetailsOperateurs.vue?vue&type=template&id=bca34266&scoped=true"
import script from "./DetailsOperateurs.vue?vue&type=script&lang=js"
export * from "./DetailsOperateurs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bca34266",
  null
  
)

export default component.exports