import utilsService from "@/services/utils/utils.service";
import localstorageService from "@/services/localstorage/localstorage.service";
import { BaseService } from "./base.service";

const { currentUserId, userRole, userData } = utilsService.currentUserUtils();

export class FormulaireService extends BaseService {
  // eslint-disable-next-line no-useless-constructor

  searchFormulaires(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/demandes/search", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
  exportFormulaires(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post("/demandes/search", data, {responseType: 'blob'})
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getFormulaire(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/demandes/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  fetchFormulaires(data) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get("/demandes", data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateFormulaire({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .put(`/demandes/${id}`, { ...data })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  updateFormulaireFormData({ id, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/demandes/${id}?_method=PUT`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getLastFormulaireCreatedByUser({ formType, userId }) {
    return new Promise((resolve, reject) => {
      const route = "/admins/last-form";
      this.axiosInstance
        .get(route, {
          params: {
            formType,
            userId,
          },
        })
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  addFormulaire({ formType, data }) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .post(`/${formType}/create`, data)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  getFormulairesGrandPublic(id) {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get(`/admins/get-all-forms/${id}`)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }
}

export default new FormulaireService();
