<template>
  <div class="">
    <b-button
      variant="outline-danger"
      @click="showModal"
    >
      Rejeter cette inscription
    </b-button>

    <b-modal
      :ref="rejectionMotivesModalRef"
      scrollable
      no-close-on-backdrop
      content-class="shadow"
      title="Rejeter cette inscription"
      size="lg"
    >
      <b-row>
        <b-col cols="12">
          <label>Motifs de rejet et marche à suivre</label>
        </b-col>
        <b-col>
          <b-form-textarea
            v-model="rejectionMotive"
            placeholder="Motif de rejet"
          />
          <div v-if="$v.rejectionMotive.$error">
            <small
              v-if="$v.rejectionMotive.required.$invalid"
              class="text-danger"
            >Ce champ est requis</small>
          </div>
        </b-col>
      </b-row>

      <template #modal-footer>
        <div class="">
          <b-button @click="hideModal">
            Annuler
          </b-button>
          <b-button
            variant="primary"
            @click="rejectEtablissementInscription"
          >
            <b-spinner
              v-if="isLoading"
              small
            /> OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BBadge,
  BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapActions } from 'vuex'
import vSelect from 'vue-select'
import AppLogo from '@/components/AppLogo.vue'
import RecaptchaWidget from '@/components/RecaptchaWidget.vue'
import localstorageService from '@/services/localstorage/localstorage.service'
import ListAgentsComponent from './agents/components/ListAgentsComponent.vue'

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
    setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules, $v: useVuelidate({ $lazy: true })  };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },

  components: {
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormFile,
    BFormTextarea,
    // validations
    AppLogo,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    RecaptchaWidget,
    BSpinner,
    BRow,
    BCol,
    ListAgentsComponent,
    BBadge,
    ToastificationContent,
    BModal,
  },
  props: {
    inputDatas: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      etsDetails: JSON.parse(JSON.stringify(this.inputDatas)),
      etablissementId: null,
      isLoading: false,
      rejectionMotivesModalRef: 'rejectionMotivesModal',
      rejectionMotive: '',
    }
  },
  validations() {
    return { rejectionMotive: { required } }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        const { etablissementId } = val.params
        this.etablissementId = etablissementId
        this.getEtablissement(etablissementId)
          .then(res => {
            this.etsDetails = res.data.resource
            console.log(this.etsDetails)
          })
          .catch(err => {})
      },
    },
  },

  mounted() {},
  methods: {
    ...mapActions('etablissements', {
      getEtablissement: 'getEtablissement',
      createEtablissement: 'createEtablissement',
      updateEtablissement: 'updateEtablissement',
      action_rejectInscriptionEtablissement: 'rejectInscriptionEtablissement',
    }),
    showModal() {
      this.$refs[this.rejectionMotivesModalRef].show()
    },
    hideModal() {
      this.$refs[this.rejectionMotivesModalRef].hide()
    },
    async rejectEtablissementInscription() {
      const isFormCorrect = await this.$v.$validate()
      if(!isFormCorrect) return
      if (this.isLoading) return
      this.isLoading = true
      this.action_rejectInscriptionEtablissement({
        etablissementId: this.etsDetails._id,
        adminEtablissmentEmail: this.etsDetails.emailEtablissement,
        motif: this.rejectionMotive,
      })
        .then(result => {
          console.log(result)
          this.$emit('onEtsRejectionSuccess', {
            isRejected: true,
            isVerified: false,
            isActive: false,
          })
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Mise à jour avec succès',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.hideModal()
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || 'Une erreur est survenue',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.img-ctn {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  .img {
    height: 100px;
    width: 100px;
    background: rgba(4, 4, 4, 0.583);
    border-radius: 5px;
  }
}
</style>
